
  /* scroll bar defination */
  
  .main-container .doc-right-section::-webkit-scrollbar {
    width: 15px;
  }
  
  .main-container .doc-right-section::-webkit-scrollbar-track {
    background: #EFF4FB;
  }
  
  .main-container .doc-right-section::-webkit-scrollbar-thumb {
    background: rgb(165, 164, 164);
  }
  
  .main-container .doc-right-section::-webkit-scrollbar-thumb:hover {
    background: #153d77;
  }
  



.doc-container{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    font-family: "DM Sans", sans-serif;
    background-color: rgba(248, 248, 255, 1);
}


.header-navbar{
    background-color: inherit;
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.nav-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 10px;

}


.logo-container{
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container img{
    width: 100%;
    height: auto;
}

.doc-link-container{
    width: 60%;
    height: auto;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    padding-right: 20px;
}


.doc-actual-link{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doc-actual-link a{
    color: rgba(123, 122, 122, 1);
    text-decoration: none;
    transition: 0.3s;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
}

.doc-actual-link a:hover{
   color: #153d77;
}


.main-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.main-container .left-section,
.main-container .doc-right-section{
    height: 100%;
    overflow-y: auto;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


.main-container .left-section{
    width: 15%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.main-container .doc-right-section{
width: 84%;
align-items: center;
}


.side-link-header{
    width: 100%;
    height: auto;
    padding: 20px;
    padding-bottom: 0;
    padding-left: 15px;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: #262e44;
    text-transform: uppercase;
    text-align: left;
}

.side-link-container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
}


.actual-side-link{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 0.9rem;
}


.actual-side-link a{
    width: 100%;
    text-decoration: none;
    color: #262e44;
    transition: 0.3s;
    padding: 2px 0px;
}
.actual-side-link a:hover{
 background-color: rgba(238, 238, 243, 0.877);
}



.doc-section{
    width: 80%;
    height: auto;
    padding: 5px;
    margin-bottom: 40px;
}


.doc-section-header{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    font-size: 2.2rem;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.doc-sub-header{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: capitalize;
}


.section-description{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 30px;
}

.section-description-example{
    width: 100%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 30px;
}


.code-section{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    font-size: 0.9rem;
    background-color: rgba(238, 238, 243, 0.877);
}

.explanation-section{
    width: 100%;
    height: auto;
    font-size: 1rem;
    font-weight: 400;
}


.url-div{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    background-color: rgba(238, 238, 243, 0.877);
    padding: 5px;
    border-radius: 3px;
    font-size: 0.9rem;

}

.url-div .method{
    width: auto;
    height: auto;
    color: rgba(248, 248, 255, 1);
    text-transform: uppercase;
    font-weight: 500;
    padding: 2px 5px;
    background-color: #3871c7;
    border-radius: 3px;
    margin-right: 10px;
}

.url-div .url{
    color: rgba(123, 122, 122, 1);
    letter-spacing: 1px;
}


.ext-link{
    font-weight: 700;
    color: blue;
}

.ext-link:active{
    color: blue;
}

.direction-url{
    font-weight: 700;
}


@media (max-width: 1110px) {
 
    .main-container .left-section{
       display: none;
    }

    .main-container .doc-right-section{
        width: 100%;
    }
  .doc-actual-link{
    width: 40%;
  }
}