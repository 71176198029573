:root {
  --white: rgba(248, 248, 255, 0.877);
  --solid-white: ghostwhite;
  --dull-white: rgba(255, 255, 255, 0.7);
  --very-dull-white: rgba(255, 255, 255, 0.4);
  --mid-white: rgba(255, 255, 255, 1);
  /* --dim-blue:  #ecebf5; */
  --dim-blue:  #EFF4FB;
  /* --dim-blue:  #e9f4ff; */
  /* --blue: #2F27CE; */
  --blue: #153d77;
  --nomal-blue:blue;
  --faint-black: rgba(0, 0, 0, 0.7);
  --dark-black: #1d253b;
  --grey-custom: #ebedef;
   --box-shadow: rgba(0, 0, 0, 0.1);
   --gray-txt: rgba(123, 122, 122, 1);
   --input-black: rgba(0, 0, 0, 0.05);
   --red: red;
   --orange: orange;
   --modal-bg : rgba(0, 0, 0, 0.2);
   --succes: #d4edda;
   --danger:#f8d7da;
   --primary:#cce5ff;
   --icon-orange : orangered;
   --new-purple : #7d2ae8;
   /* --inner-link : #0449a3; */
   --inner-link : #1b488b;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
   /* background-color: transparent; */
}


/* scroll bar defination */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--dim-blue);
  /* background-image: linear-gradient(var(--blue),  var(--dim-blue)); */
}

::-webkit-scrollbar-thumb {
  background: var(--box-shadow);
  /* border-radius: 5px; */
}

::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}

.Mui-even{
  background-color: var(--grey-custom) ;
}

.Mui-odd{
  background-color: var(--mid-white) ;
}

/* Text colors */

.my-text-blue{
  color: var(--blue);
}

.my-text-white{
  color: var(--white);
}

.my-text-red{
  color: var(--red);
}

.my-text-dull-white{
  color: var(--dull-white);
}

.my-btn-blue{
  color: var(--dull-white);
  background-color: var(--blue);
  border: none;
  font-size: 0.8rem;
  transition: 0.5s;
}

.my-btn-blue:hover{
  background-color: var(--blue);
}
.my-btn-blue:active{
 transform: scale(0.8);
}


/* side nav scroll bar defination */
/* 
.link-container ::-webkit-scrollbar {
  width: 5px;
}

 .link-container ::-webkit-scrollbar-track {
  background: var(--blue);
}

.link-container ::-webkit-scrollbar-thumb {
  background: var(--gray-txt);
  border-radius: 5px;
}

.link-container ::-webkit-scrollbar-thumb:hover {
  background: var(--dim-blue);
} */

.txt-no-deco{
text-decoration: none;
}


/* -----LOADER----- */
.loader-container{
  position: sticky;
  width: 10%;
  height: 50px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: var(--dark-black);
  background-color: var(--dull-white);
  font-size: 1.5rem;
  box-shadow: 0 0 15px var(--box-shadow);
}


/* -----ALERT----- */
.alert-error-c{
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: var(--dark-black);
  background-color: var(--succes);
  z-index: 2000;
}
.alert-error-c.special{
  position: sticky;
}
.alert-error-c.error{
  background-color: var(--danger);
}

.alert-error-c .message-c{
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.alert-error-c .al-close-btn-c{
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.5rem;
  color: var(--faint-black);
}

/*  spinner  */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  spinner  */
@keyframes pop-up {
  from {
    width: 0;
    height: 0;
  }
  to {
    max-width: 250px;
    max-height: 250px;
  }
}

.spinner{
  animation: spin infinite 1s linear;
  }

  /* no data section */

.no-data-sec{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: var(--box-shadow);
  text-transform: capitalize;
}

/* boddy */

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
  background-color: var(--solid-white);


}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--solid-white);
  /* background-image: linear-gradient(var(--blue),  var(--dim-blue)); */


}
/* Graphs  */

.main-chat-div{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -----------------------------------SHARED INDEX LAYOUT -----------------------------------------------------*/

.sharedlayout-main{
    width: 100%;
    height: 100%;
    /* max-width: 1600px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-image: linear-gradient(var(--blue),  var(--dim-blue));
}


.sharedlayout-main .top-section{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sharedlayout-main .bottom-section{
    width: 100%;
    height: 89%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sharedlayout-main .bottom-section .left-nav-container{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 5px 10px;
}

.sharedlayout-main .bottom-section .right-outlet-container{
    width: 79%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    padding: 5px 10px;
    position: relative;
}

/* ------------------------ SIDE NAV BAR SECTION-------------------------------------------- */
.side-navbar-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--dull-white);
  background-color: var(--blue);
  border-radius: 10px;
  box-shadow: 0 0 15px var(--box-shadow);
  font-weight: 300;
}



.side-navbar-main-container .side-navbar-header{
  width: 90%;
  height: 15%;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
  border-bottom: 1px solid var(--dull-white);
}

.side-navbar-main-container .side-navbar-links-section{
  width: 100%;
  height: 84%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.side-navbar-main-container .side-navbar-links-section .link-container{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  overflow-y: auto;
}

/* scroll bar */
/* scroll bar defination */

.side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-track {
  background: var(--box-shadow);
}

.side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-thumb {
  background: var(--box-shadow);
}

.side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-thumb:hover {
  background: var(--dull-white);
}



.side-navbar-main-container .side-navbar-links-section .link-container .link-header{
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  color: var(--very-dull-white);
  border-bottom: .5px solid;

}


.side-navbar-main-container .side-navbar-links-section .link-container .actual-link{
  width: 90%;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem; 
  color: var(--dull-white);
  text-decoration: none;
  margin: 5px auto;

}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link span{
  width: 70%;
  justify-content: flex-start;
  font-size: 0.8rem;
}
.side-navbar-main-container .side-navbar-links-section .link-container .actual-link:hover{
  color: var(--dark-black);
}



/* test */
.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner{
  cursor: pointer;
  flex-direction: column;
  padding: 0;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .top-lin{
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem; 
  color: var(--dull-white);
  text-decoration: none;
  margin: 5px auto;
  margin-bottom: 0;
  cursor: pointer;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .top-lin:hover{
  color: var(--dark-black);
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side{
  width: 100%;
  /* padding: 10px; */
  /* height: 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--inner-link);
  /* display: none; */
  max-height: 0; 
  overflow: hidden; 
  transition: max-height 0.5s ease-out;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side.show{
  /* display: block; */

  max-height: 600px; /* insanely high value */
  transition: max-height 1s ease-in;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link{
  width: 100%;
  height: auto;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem; 
  color: var(--dull-white);
  text-decoration: none;
  margin: 5px auto;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link span{
  width: 70%;
  justify-content: flex-start;
  font-size: 0.8rem;
}

.side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link:hover{
  color: var(--dark-black);
}











/* --------------------------------------------TOP NAV BAR ----------------------------------------------- */
.top-nav-main{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.top-nav-main .left-section{
    width: 30%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.top-nav-main .left-section .toggle-side-nav{
    width: 100%;
    height: auto;
    font-size: 1.2rem;
    color: var(--dull-white);
    display: none;
    cursor: pointer;
}


.top-nav-main .left-section .page-heading{
    width: 80%;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: var(--dull-white);
}



.top-nav-main .left-section .page-heading i{
    font-size: 1rem;
    margin-right: 3px;
}



.top-nav-main .right-section{
    width: 69%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



.top-nav-main .right-section .search-form{
    width: 40%;
    height: 100%;
    padding: 10px;
}



.top-nav-main .right-section .search-form .input-group{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.top-nav-main .right-section .search-form .input-group span{
   height: 36px;
   border-radius: 20px;
   background-color: transparent;
   border: none;
   color: var(--dull-white);
}


.top-nav-main .right-section .search-form .input-group input{
   height: 36px;
   border-radius: 20px;
   background-color: transparent;
   font-size: 0.8rem;
   border: none;
   color: var(--dull-white);
}

.top-nav-main .right-section .search-form .input-group input::placeholder {
  color: var(--dull-white);
}


.top-nav-main .right-section .search-form .input-group .form-control:focus{
 outline: none;
 box-shadow: none;
}

.top-nav-main .right-section .icon-div{
    width: 5%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--dull-white);
    position: relative;
    font-weight: 600;
    font-size: 1.2rem;
    margin: auto 5px;
}

.icon-div .inner-section{
  width: 200px ;
  height: auto;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%);
  display: none;
  z-index: 300;
  padding: 10px auto;
  background-color: var(--mid-white);
  border: 1px solid var(--gray-txt);
  border-radius: 5px;
}

.icon-div .inner-section .actual-top-devider{
 width: 100%;
 height: 1px;
 background-color:var(--gray-txt);
 margin: 0;
 color: var(--gray-txt);
}


.icon-div .inner-section .actual-top-link{
  position: relative;
}

.sub-inner-link,
.icon-div .inner-section .actual-top-link {
  width: 100%;
  height: 30px;
  font-weight: 300;
  font-size: 1rem;
  margin: 10px auto;
  color: var(--blue);
  padding-left: 10px;
  padding-right: 10px;
}

.sub-inner-link:hover,
.icon-div .inner-section .actual-top-link:hover {
 background-color: var(--primary);
}

.sub-inner-link a,
.icon-div .inner-section .actual-top-link a {
  width: 100%;
  height: 100%;
  font-weight: 300;
  color: var(--blue);
  text-decoration: none;
}

.sub-inner-link a span,
.icon-div .inner-section .actual-top-link a span{ 
  margin-left: 10px;
  font-size: 0.9rem;
  color: var(--dark-black);
}

.icon-div .inner-section .actual-top-link span{ 
  margin-left: 10px;
  font-size: 0.9rem;
  color: var(--dark-black);
}


.icon-div .inner-section .actual-top-link .sub-inner{
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  padding: 5px;
  display: none;
  background-color: var(--mid-white);
  border: 1px solid var(--gray-txt);
  border-radius: 5px;
}


.icon-div:hover .inner-section {
  display: block;
}

.icon-div .inner-section .actual-top-link:hover .sub-inner{
  display: block;
}


.actual-top-link {
  width: 100%;
  height: 30px;
  font-weight: 300;
  font-size: 1rem;
  margin: 10px auto;
  color: var(--blue);
  padding-left: 10px;
  padding-right: 10px;
}

.actual-top-link  span{ 
  margin-left: 10px;
  font-size: 0.9rem;
  color: var(--dark-black);
}


.top-nav-main .right-section .username-div{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
 }


.top-nav-main .right-section .username-div .username-txt{
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: lowercase;
  color: var(--dull-white);
 }

.top-nav-main .right-section .username-div .profile-img{
  width: 20%;
  height: 70%;
  cursor: pointer;
  background-image: url(../public/profile.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  clip-path: circle();
 }
















/* ------------------------ MOBILE SIDE NAV BAR SECTION-------------------------------------------- */
.sharedlayout-main .mobile-side-nav-container{
    width: 100%;
    height: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    display: none;
    transition: 0.3s linear;
    background-color: rgba(0, 0, 0, 0.3);
}


.mobile-side-navbar-main-container{
  width:40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--dull-white);
  background-color: var(--blue);
  box-shadow: 0 0 15px var(--box-shadow);
  position: relative;
}

.mobile-side-navbar-main-container .close-side-nav-btn{
    padding: 0;
    font-size: 1.4rem;
    background-color: transparent;
    color: var(--dull-white);
    border: none;
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(5%);
}





.mobile-side-navbar-main-container .side-navbar-header{
    width: 90%;
    height: 15%;
    padding: 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 400;
    border-bottom: 1px solid var(--dull-white);
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section{
    width: 100%;
    height: 84%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    overflow-y: auto;
  }
  
  /* scroll bar */
  /* scroll bar defination */
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-track {
    background: var(--box-shadow);
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-thumb {
    background: var(--box-shadow);
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container::-webkit-scrollbar-thumb:hover {
    background: var(--dull-white);
  }
  
  
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .link-header{
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    color: var(--very-dull-white);
    border-bottom: .5px solid;
  
  }
  
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link{
    width: 90%;
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem; 
    color: var(--dull-white);
    text-decoration: none;
    margin: 5px auto;
  
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link span{
    width: 70%;
    justify-content: flex-start;
    font-size: 0.8rem;
  }
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link:hover{
    color: var(--dark-black);
  }
  
  
  
  /* test */
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner{
    cursor: pointer;
    flex-direction: column;
    padding: 0;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .top-lin{
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem; 
    color: var(--dull-white);
    text-decoration: none;
    margin: 5px auto;
    margin-bottom: 0;
    cursor: pointer;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .top-lin:hover{
    color: var(--dark-black);
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side{
    width: 100%;
    /* padding: 10px; */
    /* height: 0; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--inner-link);
    /* display: none; */
    max-height: 0; 
    overflow: hidden; 
    transition: max-height 0.5s ease-out;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side.show{
    /* display: block; */
  
    max-height: 600px; /* insanely high value */
    transition: max-height 1s ease-in;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link{
    width: 100%;
    height: auto;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem; 
    color: var(--dull-white);
    text-decoration: none;
    margin: 5px auto;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link span{
    width: 70%;
    justify-content: flex-start;
    font-size: 0.8rem;
  }
  
  .mobile-side-navbar-main-container .side-navbar-links-section .link-container .actual-link.inner .inner_side .inner-side-link:hover{
    color: var(--dark-black);
  }
  


/* -----------------------------------------------MAIN DASHBOARD--------------------------------------- */

.main-dashboard-main{
    width: 100%;
    height: 100%;
    padding: 0 10px;
    overflow-y: auto;

}

.main-dashboard-main .top-card-container{
    width: 100%;
    margin-top: 20px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}


.main-dashboard-main .top-card-container .top-card-div{
    width: 30%;
    height: 120px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: 0 0 5px var(--box-shadow);
    border-radius: 10px;
}


.main-dashboard-main .top-card-container .top-card-div .top-info-container{
    width: 100%;
    height: 55%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
}

 
.main-dashboard-main .top-card-container .top-card-div .top-info-container .side-box{
    width: 30%;
    height: 120%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: absolute;
    background-color: var(--blue);
    box-shadow: 0 0 10px var(--box-shadow);
    left:0;
    bottom: 0;
    color: var(--white);
    font-size: 2.5rem;
}


.main-dashboard-main .top-card-container .top-card-div .top-info-container .right-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}


.main-dashboard-main .top-card-container .top-card-div .top-info-container .right-section .Header{
    width: 100%;
    height: 30%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 0.9rem;
    color: var(--gray-txt);
    font-weight: 200;
    text-transform: capitalize;
}

.main-dashboard-main .top-card-container .top-card-div .top-info-container .right-section .detail{
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 1.5rem;
    color: var(--dark-black);
    text-transform: capitalize;
    font-weight:300;
}

.main-dashboard-main .top-card-container .top-card-div .bottom-info-section{
    width: 100%;
    height: 30%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8rem;
    color: var(--gray-txt);
    text-transform: capitalize;
    font-weight: 200;
    border-top: .5px solid var(--box-shadow);
}


.main-dashboard-main .large-graph-container{
    width: 100%;
    height: 400px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8rem;
    color: var(--gray-txt);
    text-transform: capitalize;
    font-weight: lighter;
    background-color: var(--white);
    box-shadow: 0 0 5px var(--box-shadow);
}


.main-dashboard-main .large-graph-container .graph-header{
    width: 100%;
    height: 20%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.main-dashboard-main .large-graph-container .graph-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
}

.main-dashboard-main .large-graph-container .graph-header .main-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--dark-black);
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 300;
}

.main-dashboard-main .large-graph-container .graph-container{
    width: 100%;
    height: 79%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-black);
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 300;
}

.main-dashboard-main .small-graghs-container{
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding: 10px 0;

}

.main-dashboard-main .small-graghs-container .small-graph{
    width: 32%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    box-shadow: 0 0 5px var(--box-shadow);
    position: relative;
    border-radius: 5px;
}

.main-dashboard-main .small-graghs-container .small-graph .actual-graph{
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    box-shadow: 0 0 15px var(--box-shadow);
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -20px);
}

.main-dashboard-main .small-graghs-container .small-graph .actual-graph.orange{
    background-color: var(--orange);
}

.main-dashboard-main .small-graghs-container .small-graph .actual-graph.red{
    background-color: var(--red);
}

.main-dashboard-main .small-graghs-container .small-graph .graph-description{
    width: 90%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    font-weight: 300;
    text-transform: capitalize;
    color: var(--gray-txt);
}

.main-dashboard-main .small-graghs-container .small-graph .graph-description i{
  color: rgb(122, 231, 12);
  margin-right: 10px ;
  font-weight: 700;
  font-size: 1rem;
}


.main-dashboard-main .sample-transaction-container{
    width: 100%;
    height: 500px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8rem;
    color: var(--gray-txt);
    text-transform: capitalize;
    font-weight: lighter;
    background-color: var(--white);
    box-shadow: 0 0 5px var(--box-shadow);
}


.main-dashboard-main .sample-transaction-container .sample-header{
    width: 100%;
    height: 15%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.main-dashboard-main .sample-transaction-container .sample-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
} 

.main-dashboard-main .sample-transaction-container .sample-header .main-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--dark-black);
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 300;
}

.main-dashboard-main .sample-transaction-container .sample-container{
    width: 100%;
    height: 83%;
    display: flex;
    align-items: center;
    justify-content: center;
}




/* --------------------------------REGISTRATION PAGE ---------------------------------------------------- */

.main-registration{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.main-registration .registration-container{
  width: 60%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 15px var(--box-shadow) ;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--white);
}


.main-registration .registration-container .reg-left-section{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  color: var(--mid-white);
}

.main-registration .registration-container .reg-left-section .top-text{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 300;
}

.main-registration .registration-container .reg-left-section .bottom-text{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 400;
}



.main-registration .registration-container .reg-right-section{
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}



.main-registration .registration-container .reg-right-section .reg-header-text{
  width: 100%;
  height: 10%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  letter-spacing: 1px;
  font-weight: 400;
}



.main-registration .registration-container .reg-right-section .registration-form{
  width: 100%;
  height: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.8rem;
  font-weight: 200;
}



.main-registration .registration-container .reg-right-section .registration-form .reg-form-input{
  width: 90%;
  height: 36px;
  padding-left: 10px;
  padding-bottom: 0px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--box-shadow);
  transition: .3s;
}


.main-registration .registration-container .reg-right-section .registration-form .reg-form-input:focus{
outline: transparent;
border-bottom-color: var(--blue);
}



.main-registration .registration-container .reg-right-section .registration-form .reg-form-btn{
 padding: 10px 20px;
 background-color: var(--blue);
 color: var(--mid-white);
 margin: 5px auto;
 border-radius: 20px;
 border: none;
 box-shadow: 0 0 15px var(--box-shadow) ;
 transition: 0.3s;
 font-weight: 200;
}


.main-registration .registration-container .reg-right-section .registration-form .reg-form-btn:active{
 transform: scale(0.8);
}

.main-registration .registration-container .reg-right-section .reg-bottom-txt{
  width: 100%;
  height: 5%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 200;
}

.main-registration .registration-container .reg-right-section .reg-bottom-txt .reg-link{
  color: var(--blue);
  margin-left: 5px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 300;
}
.main-registration .registration-container .reg-right-section .reg-bottom-txt .reg-link:hover{
  color: var(--dark-black);
  margin-left: 5px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 300;
}


/* -------------------------------LOGIN PAGE ---------------------------------------------------- */
.main-registration .registration-container .reg-right-section .reg-header-text.login{
  height: 20%;
}

.main-registration .registration-container .reg-right-section .registration-form.login{
  width: 100%;
  height: 60%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.main-registration .registration-container .reg-right-section .registration-form.login .reg-form-input.login{
  width: 90%;
  height: 36px;
  padding: 10px;
  margin: 10px auto;
  padding-left: 10px;
  padding-bottom: 0px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--box-shadow);
  transition: .3s;
}


.main-registration .registration-container .reg-right-section .registration-form.login .reg-form-input.login:focus{
 outline: transparent;
 border-bottom-color: var(--blue) ;
}




/* --------------------------------VERIFY EMAIL---------------------------------------------------- */
.verify-email-main{
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
background-color: var(--dim-blue);
position: relative;
}

.verify-email-main .verify-email-container{
width: 50%;
height: 50%;
padding: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
/* background-color: var(--white);
box-shadow: 0 0 20px var(--box-shadow); */
}


.verify-email-main .verify-email-container .verify-email-message{
width: 100%;
height: 30%;
padding: 10px;
display: flex;
align-items: center;
justify-content: center;
font-size: 1.2rem;
text-transform: capitalize;
font-weight: 300;
}



.verify-email-main .verify-email-container .verify-email-icon{
width: 100%;
height: 50%;
padding: 10px;
display: flex;
align-items: center;
justify-content: center;
font-size: 4rem;
font-weight: 300;
text-transform: capitalize;
color: var(--blue);
}



.verify-email-main .verify-email-container .verify-email-icon.error{
color: var(--red);
}


.verify-email-main .verify-email-container .verify-email-button{
width: 100%;
height: 15%;
display: flex;
align-items: center;
justify-content: space-between;
}


.verify-email-main .verify-email-container .verify-email-button .ve-btn{
  padding: 10px 20px;
display: flex;
align-items: center;
justify-content: center;
font-size: 0.9rem;
text-transform: capitalize;
color: var(--dull-white);
background-color: var(--blue);
border-radius: 20px;
border: none;
transition: .3s;
font-weight: 200;
}


.verify-email-main .verify-email-container .verify-email-button .ve-btn:active{
 transform: scale(0.8);
}





/* -------------------------------- RESET PASSWORD ---------------------------------------------------- */
.reset-password-main{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--dim-blue);
}


.reset-password-main .rp-form{
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: var(--white); */
  /* box-shadow: 0 0 15px var(--box-shadow); */
}



.reset-password-main .rp-form .rp-header{
  width: 100%;
  height: auto;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: 500;
  padding-top: 20px;
  color: var(--blue);
}

.reset-password-main .rp-form .rp-sm-header{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 300;
}



.reset-password-main .rp-form .rp-input{
  width: 50%;
  height: 30px;
  margin: 40px auto;
  font-size: 0.8rem;
  padding-left: 10px;
  padding-bottom: 0px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--dark-black);
  transition: .3s;
  
}

.reset-password-main .rp-form .rp-input.belowc{
  margin-top: 0;
}


.reset-password-main .rp-form .rp-input:focus{
outline: transparent;
border-bottom-color: var(--blue); 
}

.reset-password-main .rp-form .rp-input:valid{
outline: transparent;
}


.reset-password-main .rp-form .rp-button{
  padding: 10px 20px;
  margin: 10px auto;
  background-color: var(--blue);
  border: none;
  border-radius: 20px;
  font-size: 0.9rem;
  color: var(--dull-white);
  transition: .3s;
  font-weight: 200;
}

.reset-password-main .rp-form .rp-button:active{
 transform: scale(0.8);
}


/* ---------------------------------------------------OPERATIONS PAGE----------------------------------------------- */

.operations-main-container{
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
} 

.operations-main-container .top-part{
  width: 100%;
  height: 63%;
  display: flex;
  align-items:center;
  justify-content: center;
}

.operations-main-container .top-part .info-section{
  width: 48%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  border-radius: 10px;
}

.operations-main-container .top-part .info-section .sample-header{
  width: 100%;
  height: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.operations-main-container .top-part .info-section .sample-header .sub-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-txt);
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 200;
}

.operations-main-container .top-part .info-section .sample-header .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 300;
}

.operations-main-container .top-part .info-section .form-cont{
 width: 100%;
 height: 79%;
 display: flex;
 align-items: center;
 justify-content:center;
}




.operations-main-container .bottom-section{
  width: 100%;
  height: 30%;
  margin-top:40px ;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
}

.operations-main-container .bottom-section .sample-header{
    width: 100%;
    height: 30%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.operations-main-container .bottom-section .sample-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
}

.operations-main-container .bottom-section .sample-header .main-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--dark-black);
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 300;
}



.operations-main-container .bottom-section .sample-container{
    width: 100%;
    height: 69%;
    display: flex;
    align-items: center;
    justify-content: center;
}




/* --------------------------------------- SALES PAGE-------------------------------------------------- */
.sales-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}


.sales-main-container .sales-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  overflow-y: auto;
}

.sales-main-container .sales-container .more-options-cont{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px auto;
}

.sales-main-container .sales-container .more-options-cont .more-options{
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--dull-white);
}
.sales-main-container .sales-container .more-options-cont .more-options i{
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--dull-white);
}

.sales-main-container .sales-container .top-part{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.sales-main-container .sales-container .sales-header{
    width: 100%;
    height: 12%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.sales-main-container .sales-container .sales-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
}

.sales-main-container .sales-container .sales-header .main-header{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sales-main-container .sales-container .sales-header .main-header .actual-header{
    width: 50%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--dark-black);
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 300;
}



.sales-main-container .sales-container .sales-header .main-header .sales-btn{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--white);
    background-color: var(--blue);
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 300;
    border: none;
    border-radius: 20px;
}



.sales-main-container .sales-container .sales-table-container{
    width: 100%;
    height: 88%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sales-data-component{
  width: auto;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* -------------------------------- MODALS ---------------------------------------------------- */

.any-modal-main{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg);
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}




.any-modal-main .sale-modal-body{
  width: 50%;
  height: 80%;
  padding: 10px;
  background-color: var(--solid-white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
}

.any-modal-main .api-modal-body{
  width: 50%;
  height: 80%;
  padding: 10px;
  background-color: var(--solid-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
}

.any-modal-main .sale-modal-body .with-data{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;
}

.any-modal-main .api-modal-body .api-data{
  width: 100%;
  height: 90%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:space-evenly;
}

.any-modal-main .api-modal-body .api-data .info-strip{
  width: 100%;
  height: 10%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;
}
.any-modal-main .api-modal-body .api-data .info-strip.formss{
  width: 100%;
  height: 25%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:space-evenly;
}


.any-modal-main .api-modal-body .api-data .info-strip .user-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}


.any-modal-main .api-modal-body .api-data .info-strip .user-info form {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.any-modal-main .api-modal-body .api-data .info-strip .user-info form textarea{
  width: 100%;
  height: auto;
  border: none;
  background-color: var(--dim-blue);

}
.any-modal-main .api-modal-body .api-data .info-strip .user-info form textarea:focus{
outline: transparent;

}

.any-modal-main .sale-modal-body .sale-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:0;
  padding: 5px;
  border: none;
  color: var(--faint-black);
}

.any-modal-main .api-modal-body .sale-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:0;
  padding: 5px;
  border: none;
  color: var(--faint-black);
}

.any-modal-main .sale-modal-body .import-txt{
  font-size: 0.7rem;
  background-color: transparent;
  display: block;
  position: absolute;
  bottom: 0;
  left:50%;
  transform: translateX(-50%);
  padding: 5px;
  border: none;
  color: var(--red);
}

.any-modal-main .api-modal-body .import-txt-api{
  font-size: 0.8rem;
  background-color: transparent;
  display: block;
  position: absolute;
  bottom: 0;
  left:50%;
  transform: translateX(-50%);
  padding: 5px;
  border: none;
  color: var(--red);
  text-transform: capitalize;
}



.inner-modal-main{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--box-shadow);
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-modal-main .inner-modal-body{
  width: 50%;
  height: 80%;
  padding: 10px;
  background-color: var(--solid-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}


.inner-modal-main .inner-modal-body.smallc{
  width: 40%;
  height: auto;
  padding: 10px;
  background-color: var(--solid-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding:50px;
  border-radius: 20px;
}


.inner-modal-main .inner-modal-body.smallc .inner-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:2%;
  padding: 5px;
  border: none;
  color: var(--faint-black);
}

.inner-modal-main .inner-modal-body .inner-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:0;
  padding: 5px;
  border: none;
  color: var(--faint-black);
}

/* .inner-modal-main .inner-modal-body .inner-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:0;
  padding: 5px;
  border: none;
  color: var(--faint-black);
} */

.inner-modal-main .inner-modal-body .verification-text{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 20px;
}

.inner-modal-main .inner-modal-body .verification-buttons{
  width: 100%;
  height: 39%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 5px;
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: capitalize;
}

.inner-modal-main .inner-modal-body .verification-buttons button{
  margin-right: 10px;
}

.inner-modal-main .inner-modal-body .import-txt{
  font-size: 0.7rem;
  background-color: transparent;
  display: block;
  position: absolute;
  bottom: 0;
  left:50%;
  transform: translateX(-50%);
  padding: 5px;
  border: none;
  color: var(--red);
  text-transform: capitalize;
}



/* -----------NEW TICKET MODAL---------- */

.new-ticket-modal-container{
  width: 50%;
  height: 60%;
  padding: 10px;
  background-color: var(--solid-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
}

.new-ticket-modal-container .nt-close-modal-btn{
  font-size: 0.9rem;
  background-color: transparent;
  display: block;
  position: absolute;
  top: 0;
  right:0;
  padding: 5px;
  border: none;
  color: var(--faint-black);
}

.new-ticket-modal-container .nt-close-modal-btn:hover{
  color: var(--dark-black);
}

.new-ticket-modal-container .new-ticket-header{
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-transform: capitalize;
}


.new-ticket-modal-container .new-ticket-form{
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-transform: capitalize;
}


.new-ticket-modal-container .new-ticket-form .nt-form-input{
  width: 70%;
  height: 30px;
  padding: 10px;
  font-size: 0.9rem;
  margin: 10px;
}

.new-ticket-modal-container .new-ticket-form .nt-form-input.biggr{
  height: 40px;
  padding: 10px;
}


.new-ticket-modal-container .new-ticket-form .nt-form-input:focus,
.new-ticket-modal-container .new-ticket-form .nt-form-area:focus{
 outline: transparent;
}


.new-ticket-modal-container .new-ticket-form .nt-form-area{
  width: 70%;
  padding: 10px;
  font-size: 0.9rem;
  margin: 10px;
}


.new-ticket-modal-container .new-ticket-form .nt-form-btn{
  padding: 10px 20px ;
  font-size: 0.9rem;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 20px;
  border: none;
  margin: 10px;
  box-shadow: 0 0 15px var(--box-shadow) ;
}

.new-ticket-modal-container .new-ticket-form .nt-form-btn:active{
  transform: scale(0.8);
}




/* --------------------------------------------- SINGLE USER PAGE------------------------------------- */
.single-user-main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  /* position: relative; */
}
.single-user-main .more-options-cont{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px auto;
}

.single-user-main .more-options-cont .more-options{
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--dull-white);
}

.single-user-main .more-options-cont .more-options i{
  font-size: 1.2rem;
}

.single-user-main .top-part{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-user-main .top-part .left-side-cont{
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.single-user-main .top-part .left-side-cont .profile-img-div{
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  border-radius: 5px 0 0 5px;

}

.single-user-main .top-part .left-side-cont .profile-img-div .user-img{
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 0;

}
.single-user-main .top-part .left-side-cont .profile-img-div .user-img .actual-img{
  width: 60%;
  height: 80%;
  background-image: url("../public/cmp.jfif");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  clip-path: circle();

}

.single-user-main .top-part .left-side-cont .profile-img-div .user-img-info{
  width: 100%;
  height: 39%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 0 0 5px;

}

.single-user-main .top-part .left-side-cont .profile-img-div .user-img-info .user-name{
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
}


.single-user-main .top-part .left-side-cont .profile-img-div .user-img-info .user-desc{
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: 0;
  color: var(--gray-txt);
}
.single-user-main .top-part .left-side-cont .profile-img-div .user-img-info .client-buttons button{
  padding: 10px 20px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dull-white);
  border: none;
  border-radius: 20px;
  font-size: 0.8rem;

}
.single-user-main .top-part .left-side-cont .profile-img-div .user-img-info .client-buttons:active{
  transform: scale(0.8);

}

.single-user-main .top-part .left-side-cont .profile-info-div{
  width: 69%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: var(--white);
  box-shadow: 0 0 15px var(--box-shadow);
  border-radius: 0  5px 5px 0;
  padding-left: 5px;

}


.single-user-main .top-part .left-side-cont .profile-info-div .info-strip{
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

}

.single-user-main .top-part .left-side-cont .profile-info-div .info-strip .user-info{
  width: 32%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.single-user-main .top-part .left-side-cont .profile-info-div .info-strip .user-info .info-header{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  font-size: 0.7rem;
  color: var(--gray-txt);
  text-transform: capitalize;
  margin-bottom: 0;
}

.single-user-main .top-part .left-side-cont .profile-info-div .info-strip .user-info .info-header i{
 margin-right: 3px;
 font-size: 0.8;
 color: var(--icon-orange);
 font-weight: 500;
}


.single-user-main .top-part .left-side-cont .profile-info-div .info-strip .user-info .info-details{
  width: 100%;
  height: auto;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 0;
  overflow-wrap: break-word;
}




/* -------------- */






.single-user-main .mid-part{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    padding: 10px 0;
    background-color: var(--white);
    box-shadow: 0 0 5px var(--box-shadow);
    border-radius: 0  5px 5px 0;
    padding-left: 5px;
}

.single-user-main .mid-part .sample-header{
  width: 100%;
  height: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.single-user-main .mid-part .sample-header .sub-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-txt);
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 200;
}

.single-user-main .mid-part .sample-header .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 300;
}


.single-user-main .mid-part-two{
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  border-radius: 0  5px 5px 0;

}

.single-user-main .mid-part-two .inner-section{
  width: 49%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  padding: 10px 0;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  border-radius: 0  5px 5px 0;
  padding-left: 5px;
}

.inner-comp-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.inner-comp-container .sample-header{
  width: 100%;
  height: 20%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.inner-comp-container .sample-header .sub-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-txt);
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 200;
}

.inner-comp-container .sample-header .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 300;
}

.inner-comp-container .kyc-section{
  width: 100%;
  height: 79%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-comp-container .api-section{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-comp-container .reset-btn-section{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-comp-container .reset-btn-section button{
  padding: 10px 20px;
  font-weight: 300;
  font-size: 0.8rem;
  transition: 0.3s;
  border-radius: 20px;
  background-color: var(--red);
  color: var(--white);
  border: none;
}

.inner-comp-container .reset-btn-section button:active{
 transform: scale(0.8);
}








.single-user-main .bottom-part{
  width: 100%;
  height: auto;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--danger);
  padding: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
}


.single-user-main .bottom-part-one{
  width: 100%;
  height: auto;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0 0 15px var(--danger);
  padding: 10px;
}


.single-user-main .bottom-part .admin-btn{
  padding: 10px 20px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dull-white);
  border: none;
  border-radius: 20px;
  transition: 0.3s;
  font-size: 0.8;
  font-weight: 300;
}

.single-user-main .bottom-part .admin-btn.dang{
  background-color: var(--red);
}

.single-user-main .bottom-part .admin-btn:active{
 transform: scale(0.8);
}


.delete-profile-cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 5px;
}

.delete-profile-cont .txt-header,
.delete-profile-cont .txt-para{
  width: 100%;
  height: auto;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.delete-profile-cont .txt-para{
  font-size: 0.8rem;
  font-weight: 400;
}

.delete-profile-cont .del-form-cont{
   width: 100%;
   height: auto;
}

.delete-profile-cont .del-form-cont .del-user-form{
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

.delete-profile-cont .del-form-cont .del-user-form .input-group{
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin: 10px auto;
}


.delete-profile-cont .del-form-cont .del-user-form .input-group input{
   width: 80%;
   height: 40px;
   font-size: 0.8rem;
   padding-left: 10px;
   border-radius: 4px 0 0 4px;
}

.delete-profile-cont .del-form-cont .del-user-form .input-group input:focus,
.delete-profile-cont .del-form-cont .del-user-form .input-group input:valid{
  outline: transparent;
}

.delete-profile-cont .del-form-cont .del-user-form .input-group span{
   width: auto;
   height: 40px;
   font-size: 0.9rem;
   color: var(--icon-orange);
}


/* --------------------------------------------PROFILE PAGE------------------------------------------- */
.profile-main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.profile-main .user-details-container{
  width: 100%;
  height: 300px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.profile-main .user-details-container .details-section{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  padding: 10px;
}


.profile-main .user-details-container .details-section.fullength{
  width: 98%;
}



.profile-main .user-details-container .details-section .hearder-txt{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  color: var(--dark-black);
  text-transform: capitalize;
}

.profile-main .user-details-container .details-section .info-div{
  width: 100%;
  height: 89%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
}


.profile-main .user-details-container .details-section .info-div .profile-image{
  width: 80%;
  height: 80%;
  background-image: url("../public/cmp.jfif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* clip-path: circle(); */
}


.profile-main .user-details-acc-container{
  width: 98%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--white);
  box-shadow: 0 0 5px var(--box-shadow);
  padding: 10px;
  margin: 10px auto;
}

.profile-main .user-details-acc-container .details-actual{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px auto;
}

.profile-main .user-details-acc-container .details-actual .hearder-txt{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  color: var(--dark-black);
  text-transform: capitalize;
}

.profile-main .user-details-acc-container .details-actual .reg-div{
  width: 40%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  color: var(--dark-black);
  text-transform: capitalize;
}

.profile-main .user-details-acc-container .details-actual .acc-info-div{
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 300;
  color: var(--dark-black);
  text-transform: capitalize;
  overflow-y: auto;
}




.edit-user-form-container{
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
}


.edit-user-form-container  .edit-user-form{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.8rem;
  font-weight: 200;
}

.edit-user-form .input-group{
  width: 100%;
  margin: 5px auto;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .edit-user-form .input-group span{
  height: 40px;
  font-size: 1rem;
  color: var(--icon-orange);  
 }
 .edit-user-form .input-group select,
 .edit-user-form .input-group input {
   width: 80%;
   height: 40px;
   font-size: 0.7rem;
   padding-left: 10px;
   border-radius: 0 5px 5px 0;
 }
 .edit-user-form .input-group select:focus,
 .edit-user-form .input-group input:focus {
   outline: transparent;
 }
 




.edit-user-form-container  .edit-user-form .edit-user-btn{
 padding: 10px 20px;
 background-color: var(--blue);
 color: var(--mid-white);
 margin: 5px auto;
 border-radius: 20px;
 border: none;
 box-shadow: 0 0 15px var(--box-shadow) ;
 transition: 0.3s;
 font-weight: 200;
}

.edit-user-form-container  .edit-user-form .edit-user-btn:active{
 transform: scale(0.8);
}

.edit-user-btn{
 padding: 10px 20px;
 background-color: var(--blue);
 color: var(--mid-white);
 margin:  auto;
 border-radius: 20px;
 border: none;
 box-shadow: 0 0 15px var(--box-shadow) ;
 transition: 0.3s;
 font-weight: 200;
 font-size: 0.8rem;
}

.edit-user-btn:active{
 transform: scale(0.8);
}


/* ------------------------------- AIRTIME ACCOUNT DETAILS-------------------------  */

.airtime-acc-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.airtime-acc-container .airtime-account{
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 0 15px var(--grey-custom);
  border-radius: 10px;
  background-color: var(--solid-white);
}

.airtime-acc-container .airtime-account.api{
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: none;
  border-radius: 10px;
}


.airtime-acc-container .airtime-account.full-leng{
  width: 100%;
}




.airtime-acc-container .airtime-account .acc-info{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 300;
  color: var(--gray-txt);
  margin: 5px 0;
}


.airtime-acc-container .airtime-account .acc-info span{
  width: 70%;
  text-align: left;
  color: var(--dark-black);
  font-weight: 600;
}

.airtime-acc-container .airtime-account .buttons-div{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 300;
  padding: 20px;
}

.airtime-acc-container .airtime-account .buttons-div button{
  padding: 10px 20px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dull-white);
  border: none;
  border-radius: 20px;
}


/* --------------------------------------------SEARCH TRANS PAGE------------------------------------------- */
.search-trans-main{
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--white);
}

.search-trans-main .st-toppart{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.search-trans-main .st-toppart .search-form{
  width: 30%;
  height: 100%;
  padding: 10px;
}



.search-trans-main .st-toppart .search-form .input-group{
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}

.search-trans-main .st-toppart .search-form .input-group span{
 height: 36px;
 border-radius: 0;
 border: none;
 border-bottom: solid 1px;
 background-color: transparent;
}


.search-trans-main .st-toppart .search-form .input-group input{
 height: 36px;
 border-radius: 0;
 border: none;
 border-bottom: solid 1px;
 background-color: transparent;
 font-size: 0.8rem;
}


.search-trans-main .st-toppart .input-group .form-control:focus{
outline: none;
box-shadow: none;
}


.search-trans-main .st-midpart{
  width: 100%;
  height: 69%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  /* box-shadow: 0 0 15px var(--box-shadow); */
  margin-top: 20px;
}

.search-trans-main .st-midpart .no-data{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-trans-main .st-midpart .with-data{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}




.search-trans-main .st-midpart .with-data .info-strip{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

}

.search-trans-main .st-midpart .with-data .info-strip .user-info{
  width: 32%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.search-trans-main .st-midpart .with-data .info-strip .user-info .info-header{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  font-size: 0.7rem;
  color: var(--gray-txt);
  text-transform: capitalize;
  margin-bottom: 0;
}


.search-trans-main .st-midpart .with-data .info-strip .user-info .info-details{
  width: 100%;
  height: auto;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 0;
  overflow-wrap: break-word;
}

.search-trans-main .st-midpart .with-data .info-strip .user-info .info-details.numc{
font-size: 2rem;
font-weight: 400;
color: var(--orange);
}

.search-trans-main .st-bottompart{
  width: 100%;
  height: auto;
  margin: 40px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}


.search-trans-main .st-bottompart .trans-btn{
  padding: 10px 20px;
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dull-white);
  border: none;
  border-radius: 20px;
}



/* ---------------------------------TRANSACTION STATUS DETAILS------------------------ */

.info-strip{
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

}

.info-strip .user-info{
  width: 32%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-strip .user-info.fullc{
  width: 98%;
}


 .info-strip .user-info .info-header{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  font-size: 0.7rem;
  color: var(--gray-txt);
  text-transform: capitalize;
  margin-bottom: 0;
}


.info-strip .user-info .info-details{
  width: 100%;
  height: auto;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0;
  overflow-wrap: break-word;
}


.info-strip .user-info .info-details.numc{
font-size: 2rem;
font-weight: 400;
color: var(--orange);
}

/* --------------------------------------- All User PAGE-------------------------------------------------- */
.user-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}


.user-main-container .user-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 15px var(--box-shadow);
}

.user-main-container .user-container .user-header{
    width: 100%;
    height: 10%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.user-main-container .user-container .user-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
}
/* 
.user-main-container .user-container .user-header .main-header{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.user-main-container .user-container .user-header .main-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--dark-black);
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 300;
}



.user-main-container .user-container .user-header .main-header .user-btn{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--white);
    background-color: var(--blue);
    font-size: 0.8rem;
    text-transform: capitalize;
    font-weight: 300;
    border: none;
    border-radius: 20px;
}

.user-main-container .user-container .new-user-btn-div{
  width: 100%;
  height: 10%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  font-weight: 400;
}

.user-main-container .user-container .user-table-container{
    width: 100%;
    height: 79%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-main-container .user-container .user-table-container.rep{
    width: 100%;
    height: 89%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* ------------------modal reg form ----------------*/
.registration-form{
  width: 100%;
  height: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 0.8rem;
  font-weight: 200;
}

.registration-form .reg-form-input{
  width: 90%;
  height: 36px;
  padding-left: 10px;
  padding-bottom: 0px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--box-shadow);
  transition: .3s;
}

.registration-form .reg-form-input:focus{
outline: transparent;
border-bottom-color: var(--blue);
}


.registration-form .reg-form-btn{
 padding: 10px 20px;
 background-color: var(--blue);
 color: var(--mid-white);
 margin: 5px auto;
 border-radius: 20px;
 border: none;
 box-shadow: 0 0 15px var(--box-shadow) ;
 transition: 0.3s;
 font-weight: 200;
}


/* ---------------------DISCOUNT PAGE--------------------------------- */
.discounts-main-container{
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}


.discounts-main-container .discount-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0 0 15px var(--box-shadow);
}

.discounts-main-container .discount-container .discount-header{
    width: 100%;
    height: 10%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.discounts-main-container .discount-container .discount-header .sub-header{
    width: 100%;
    height: 40%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-txt);
    font-size: 0.9rem;
    text-transform: capitalize;
    font-weight: 200;
}

.discounts-main-container .discount-container .discount-header .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 400;
}

 


 /* --------------------------------------------------SETTINGS PAGE ------------------------------------------------------------------------ */

 .settings-page{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
 } 



.settings-sections{
  width: 100%;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0 0 20px var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px;
  margin-bottom: 10px;
  position: relative;
  /* overflow-y: auto; */
}

.settings-sections.cust{
  width: 100%;
  height: 100%;
  background-color: var(--white);
  box-shadow: 0 0 20px var(--box-shadow);
 display: block;
  padding: 40px;
  margin-bottom: 10px;
  position: relative;
  overflow-y: auto;
}


.settings-sections .actual-constants,
.settings-sections .constants-update-section{
  width: 100%;
  height: 25%;
  background-color: var(--white);
  border: 0.5px solid var(--box-shadow);
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px auto;
  overflow-y: auto;
}

.settings-sections.cust .constants-update-section{
  height: 40%;
}
.settings-sections .actual-constants{
  height: 55%;
}

.settings-sections .actual-constants.fullc {
  height: 85%;
  overflow-y: auto;
}

.settings-sections .constants-update-section{
  border: none;
}



.constant-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  overflow-y: auto;
}

.constant-container .constant-div{
  width: 40%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  margin-top: 5px;
}



.constant-container .constant-div p{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0;
  margin: 0 auto;
  padding-left: 10px;
}

.constant-container .constant-div p span{
  width: 60%;
  font-weight: 600;
  text-transform: capitalize;
}

.constant-container .constant-div p .value{
 color: var(--icon-orange);
}


.constant-container .kyc-doc-div{
  width: 45%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--succes);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 20px;

}



.constant-container .kyc-doc-div p{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--blue);
  margin: 0;
}



.constant-form{
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
   padding: 5px;
}

.constant-form.fullcModal{
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
   padding: 5px;
}

.constant-form .form-header{
 width: 100%;
 text-align: center;
 font-size: 0.9rem;
 font-weight: 300;
 text-transform: capitalize;
 padding: 5px;
 margin: 0;
}

.constant-form .input-group{
 width: 60%;
 margin: 5px auto;
}

.constant-form .input-group span{
  height: 40px;
 font-size: 1rem;
 color: var(--icon-orange);
}
.constant-form .input-group select,
.constant-form .input-group input {
  width: 80%;
  height: 40px;
  font-size: 0.7rem;
  padding-left: 10px;
  border-radius: 0 5px 5px 0;
}
.constant-form .input-group select:focus,
.constant-form .input-group input:focus {
  outline: transparent;
}


.constant-form .input-group input.dtwo {
  width: 60%;
  font-size: 0.7rem;
  padding-left: 10px;
  border-radius: 0 5px 5px 0;
}

.constant-form .input-group input.dwone {
  width: 20%;
  font-size: 0.7rem;
  padding-left: 10px;
  border-radius: 0 5px 5px 0;
  margin: 0 3px 0 3px;
}

/* ----------------------- */

.company-reg-form{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px;
}



.company-reg-form .input-group{
  width: 49%;
  margin: 5px auto;
 }
 
 .company-reg-form .input-group span{
   height: 40px;
  font-size: 1rem;
  color: var(--icon-orange);
 }
 .company-reg-form .input-group select,
 .company-reg-form .input-group input {
   width: 80%;
   height: 40px;
   font-size: 0.7rem;
   padding-left: 10px;
   border-radius: 0 5px 5px 0;
 }
 .company-reg-form .input-group select:focus,
 .company-reg-form .input-group input:focus {
   outline: transparent;
 }
 
 
 .company-reg-form .input-group input.dtwo {
   width: 60%;
   font-size: 0.7rem;
   padding-left: 10px;
   border-radius: 0 5px 5px 0;
 }
 
 .company-reg-form .input-group input.dwone {
   width: 20%;
   font-size: 0.7rem;
   padding-left: 10px;
   border-radius: 0 5px 5px 0;
   margin: 0 3px 0 3px;
 }



 





.section-header{
  width: 100%;
  height: 15%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.section-header .sub-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-txt);
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 200;
}

.section-header .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 300;
}


/* --------------------------------SINGLE TICKET PAGE ---------------------------------------------------- */

.single-ticket-page-main{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--white);
  overflow-y: auto;
}


.single-ticket-page-main .stp-ticket-section{
  width: 60%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.single-ticket-page-main .stp-ticket-section .ticket-details{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--icon-orange);
}

.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-owner{
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-time{
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-status{
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-owner span,
.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-time span,
.single-ticket-page-main .stp-ticket-section .ticket-details .ticket-status span{
  margin-left: 10px;
  font-size: 0.8rem;
  text-transform: capitalize;
  color: var(--gray-txt);
}


.single-ticket-page-main .stp-ticket-section .ticket-title{
  width: 100%;
  height: 15%;
  padding: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-transform: capitalize;
  letter-spacing: 1px;
}


.single-ticket-page-main .stp-ticket-section .ticket-description{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  text-align: center;
  line-height: 20px;
}



.single-ticket-page-main .stp-ticket-section .ticket-buttons {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 0.9rem;
}


.single-ticket-page-main .stp-ticket-section .ticket-buttons button {
  padding: 10px 20px;
  border-radius: 20px;
  background-color: var(--blue);
  color: var(--dull-white);
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  transition: .3s;
}


.single-ticket-page-main .stp-ticket-section .ticket-buttons button:active {
  transform: scale(0.8);
}

.single-ticket-page-main .stp-ticket-section .ticket-buttons .tb-btn-reopen{
background-color: var(--orange);
}

.single-ticket-page-main .stp-ticket-section .ticket-buttons .tb-btn-delete{
background-color: var(--red);
}



.single-ticket-page-main .stp-comments-section{
  width: 40%;
  height: 100%;
  /* padding: 10px 0; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--box-shadow);
  border-radius: 10px;
  overflow: hidden;
}


.single-ticket-page-main .stp-comments-section .ticket-comments{
  width: 100%;
  height: 90%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto ;
}

.single-ticket-page-main .stp-comments-section .ticket-comments.history{
  height: 100%;
}


.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment{
  width: 100%;
  height: auto;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment.left{
  justify-content: flex-start;
}
.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment.right{
 justify-content: flex-end;
}
.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment .comment-bubble{
  width: 80%;
  height: auto;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
 font-size: 0.8rem;
 background-color: var(--dull-white);
 border-radius: 10px;
}



.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment .comment-txt{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.single-ticket-page-main .stp-comments-section .ticket-comments .actual-comment .comment-time{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.6rem;
  color: var(--faint-black);
}










.single-ticket-page-main .stp-comments-section .ticket-comments-form{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
   background-color: var(--dim-blue);
}




.single-ticket-page-main .stp-comments-section .ticket-comments-form .create-comment-form{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}


.single-ticket-page-main .stp-comments-section .ticket-comments-form .create-comment-form input{
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
}


.single-ticket-page-main .stp-comments-section .ticket-comments-form .create-comment-form input:focus{
outline: transparent;
}


.single-ticket-page-main .stp-comments-section .ticket-comments-form .create-comment-form button{
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 1.5rem;
  color: var(--blue);
  background-color: transparent;
  border: none;
}



/* --------------------------------MY TICKETS PAGE ---------------------------------------------------- */

.my-tickets-main{
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.my-tickets-main .my-tickets-container{
  width: 98%;
  height: 98%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--white);
  box-shadow: 0 0 15px var(--box-shadow) ;
}


.my-tickets-main .my-tickets-container .new-ticket-div{
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.my-tickets-main .my-tickets-container .new-ticket-div .new-ticket-btn {
  padding: 10px 20px;
  background-color: var(--blue);
  color: var(--white);
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.my-tickets-main .my-tickets-container .my-tickets-data {
  width: 100%;
  height: 89%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}



.sample-tickets-component{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.sample-tickets-component .stc-header-section{
  width: 100%;
  height: 10%;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
}

.sample-tickets-component .stc-header-section span{
  font-size: 0.8rem;
  margin-left: 5px;
}

.sample-tickets-component .stc-data-section{
  width: auto;
  height: 90%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
 




/*------------------------------------------------ test login---------------------------------- */

.log-reg-page{
  width: 100%;
  height: 100%;
  background: white;
  /* background-image: url("../public/newbg.png"); */
  background-size:contain;
  background-position:left;
  background-repeat:no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

 .log-logo{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
}
.log-logo .log-logo-image{
  width: 150px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log-logo .log-logo-image img{
  width: 100%;
  height: auto;
}

.log-icon-container{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.log-icon-container .log-icons{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}

.log-icon-container .log-icons a{
  padding-right: 5px;
  margin: 2px 10px;
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--blue);
}

/* new additions */

.illus-container{
  width: 450px;
  height: 400px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 40;
}


.illus-container img{
  width: 100%;
  height: auto;
}

.top-circle{
  width: 200px;
  height: 200px;
  background-color: #609FFA;
  clip-path: circle();
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%,-50%);
  animation-name: pop-up;
  animation-duration: 1s;

}


/*  end of new additions */



.container{
  position: relative;
  max-width: 850px;
  width: 100%;
  background: var(--solid-white);
  padding: 40px 30px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  /* perspective: 2700px; */
  z-index: 80;
}
.container .cover{
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
  z-index: 3000;
}


 .container .cover .front,
 .container .cover .back{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--blue);
}

.cover .front{
  backface-visibility: hidden;
}

.cover .back{
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
/* .container .cover::before,
.container .cover::after{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--blue);
  opacity: 0.5;
  z-index: 12;
}

.container .cover::after{
  opacity: 0.3;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.container .cover img{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 10;
} */


.container .cover .text{
  position: absolute;
  z-index: 130;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover .text .text-1,
.cover .text .text-2{
  font-size: 40px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
  margin-bottom: 40px;
}
.cover .text .text-2{
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}


/* flip the cover */
.container #flip:checked ~ .cover{
  transform: rotateY(-180deg);
}



.container .forms{
  height: 100%;
  width: 100%;
  /* background: var(--white); */
  background-color: transparent;
}

.container .form-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-content .login-form,
.form-content .signup-form{
  width: calc(100% / 2 - 25px);
  position: relative;
}
.forms .form-content .title{
  position: relative;
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.forms .form-content .input-boxes{
  margin-top: 30px;
}
.forms .form-content .input-box{
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  margin: 20px 0;
  position: relative;
}
.form-content .input-box input{
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 30px;
  font-size: 0.8rem;
  font-weight: 500;
  border-bottom: 2px solid rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}
.form-content .input-box input:focus,
.form-content .input-box input:valid{
  border-color: var(--blue);
}
.form-content .input-box i{
  position: absolute;
  color: var(--blue);
  font-size: 1.2rem;
}
.forms .form-content .text{
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.forms .form-content .text a{
  text-decoration: none;
  color: var(--nomal-blue);
}
.forms .form-content .text a:hover{
  text-decoration: underline;
}
.forms .form-content .button{
  color: var(--white);
  margin-top: 40px;
}
.forms .form-content .button input{
  color: var(--white);
  background: var(--blue);
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}

.forms .form-content .button button{
  display: block;
  width: 100%;
  height: 100%;
  color: var(--white);
  background: var(--blue);
  border-radius: 6px;
  border: none;
  padding: 0;
  transition: all 0.4s ease;
}



.forms .form-content label{
  color: var(--nomal-blue);
  cursor: pointer;
}
.forms .form-content label:hover{
  text-decoration: underline;
}
.forms .form-content .login-text,
.forms .form-content .sign-up-text{
  text-align: center;
  margin-top: 25px;
}
.container #flip{
  display: none;
}


/* ----------------------------------PAGE NOT FOUND----------------------------------- */
.not-found-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.not-found-container .nf-number,
.not-found-container .nf-text{
  width: 100%;
  height:auto;
  margin: 0 auto;
  text-align: center;
}

.not-found-container .nf-number{
  font-size: 3rem;
  color: var(--blue);
  letter-spacing: 1px;
  font-weight: 600;
}

.not-found-container .nf-txt{
  font-size: 0.9rem;
  color: var(--dark-black);
  font-weight: 400;
}


/* -------------------------------------------------------------------- */
/* ----------------------NEW Additions By Dev ------------------------ */
/* ------------------------------------------------------------------ */

/* -------------------- SEARCH BY DATE FORM---------------------------- */

.search-date-form-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.search-date-form-container .search-date-form-hearder{
  width: 100%;
  height: auto;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 0;
  color: var(--gray-txt);
}


.search-date-form-container .search-date-form{
  margin-top: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.search-date-form-container .search-date-form  .input-group{
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
}


.search-date-form-container .search-date-form  .input-group input{
  width: auto;
  height: 35px;
  font-size: 0.8rem;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 0 5px  5px 0;
}

.search-date-form-container .search-date-form  .input-group input:focus,
.search-date-form-container .search-date-form  .input-group input:valid{
 outline: transparent;
}

.search-date-form-container .search-date-form  .input-group span{
  width: auto;
  height: 35px;
  font-size: 0.9rem;
  background-color: var(--grey-custom);
  font-size: 0.8rem;
}


.search-date-form-container .search-date-form .search-date-btn{
  height: 30px;
  padding: 0 20px;
  font-size: 0.8rem;
  background-color: var(--blue);
  color: var(--dull-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
}

.search-date-form-container .search-date-form .search-date-btn:active{
  transform: scale(0.9);
}



/*--------------- Sales header custom-------------------------------------------------------- */

.sales-main-container .sales-container .sales-header-cus{
  width: 100%;
  height: 12%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sales-main-container .sales-container .sales-header-cus .left-sect{
  width: 40%;
  height: 100%;
  padding: 10px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sales-main-container .sales-container .sales-header-cus .left-sect .sub-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--gray-txt);
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 200;
}

.sales-main-container .sales-container .sales-header-cus .left-sect .main-header{
  width: 100%;
  height: 40%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-black);
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 300;
}


.sales-main-container .sales-container .sales-header-cus .right-sect{
  width: 58%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}












/* -----------------------------TABLET VIEW SECTION ------------------------------------------------------------ */




@media (max-width: 1110px) {
  
  /* -------------LOG REG SECTIONS---------------- */

  .container{
    width: 90%;
    border-radius: 10px;
    padding: 20px 10px;
  }

  .top-circle,
  .illus-container{
    display: none;
  }
  
  /* ---------------------------------------------MOBILE SIDE NAV DIV--------------------- */

  .sharedlayout-main .mobile-side-nav-container{
    display: block;
    width: 0;
    overflow: hidden;

}

  .sharedlayout-main .mobile-side-nav-container.show-nav-bar{
    width: 100%;  
}




  /* ----------------------------lOGO AND iCON DIV-------------------------- */


  .log-logo{
    height: 80px;
  }
  .log-logo .log-logo-image{
    width: 100px;
    height: 80px;
  }
  
  .log-icon-container .log-icons{
    justify-content: center;
  }
  



  /* ------------------------------LOGIN REGISTRATION------------------------ */
  .log-reg-page{
    width: 100%;
    height: 100%;
    background: var(--solid-white);
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }





  /* ------------------------------FORGOT/RESET PASSWORD ------------------------ */
  
.reset-password-main{
  background-color: var(--solid-white);
}


.reset-password-main .rp-form{
  position: relative;
  width: 100%;
  height: 70%;
  padding: 10px;
  background-color: var(--white);
  box-shadow:none;
}



.reset-password-main .rp-form .rp-header{
  font-size: 2rem;
}

.reset-password-main .rp-form .rp-sm-header{
  font-size: 0.9rem;
}



.reset-password-main .rp-form .rp-input{
  width: 70%;
  height: 40px;
  margin-top: 30px;
  
}




/* -------------------------------------------EMAIL VERIFICATION---------------------------------- */

.verify-email-main{
  background-color: var(--solid-white);
  }
  
  .verify-email-main .verify-email-container{
  width: 90%;
  height: 60%;
  box-shadow: none;
  }
  
  
  .verify-email-main .verify-email-container .verify-email-message{
  font-size: 1.1rem;
  font-weight: 400;
  }
   
  .verify-email-main .verify-email-container .verify-email-button .ve-btn{
    padding: 10px 30px;
  }



  /* ----------------------------------SHARED INDEX PAGE------------------------------- */


  


.sharedlayout-main .bottom-section{
  width: 100%;
  height: 89%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sharedlayout-main .bottom-section .left-nav-container{
display: none;
}

.sharedlayout-main .bottom-section .right-outlet-container{
  width: 100%;
}







/* --------------------------------------------TOP NAV BAR ----------------------------------------------- */





.top-nav-main .left-section{
  width: 20%;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.top-nav-main .left-section .toggle-side-nav{
  display: block;
}


.top-nav-main .left-section .page-heading{
display: none;
}



.top-nav-main .right-section{
  width: 79%;
  justify-content: flex-end;
}


/* .top-nav-main .right-section .search-form{
 display: none;
} */


.top-nav-main .right-section .icon-div{
  width: 10%;
  font-size: 1rem;
}


.icon-div .inner-section{
width: 200px ;
height: auto;
position: absolute;
top: 100;
left: 50;
transform: translateX(-100%);
display: none;
z-index: 300;
padding: 10px auto;
background-color: var(--mid-white);
border: 1px solid var(--gray-txt);
border-radius: 5px;
}



.top-nav-main .right-section .username-div{
width: 20%;
}


.top-nav-main .right-section .username-div .username-txt{
display: none;
}

.top-nav-main .right-section .username-div .profile-img{
width: 80%;
height: 70%;
}

/* -----------------------------------------------------MAIN DASHBOARD-------------------------------------------- */
  
  
.main-dashboard-main{
  width: 100%;
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
}





.main-dashboard-main .top-card-container .top-card-div .top-info-container .side-box{
  font-size: 2rem;
}


.main-dashboard-main .large-graph-container .graph-header .sub-header{
  font-size: 0.8rem;
}

.main-dashboard-main .large-graph-container .graph-header .main-header{
  font-size: 1rem;
}

.main-dashboard-main .large-graph-container .graph-container{
  padding: 0;
}

.main-dashboard-main .small-graghs-container{
  height: auto;
  flex-wrap: wrap;

}


.main-dashboard-main .small-graghs-container .small-graph{
  width: 30%;
  height: 250px;
  margin-bottom: 40px;
}





/* --------------------------------------------- SINGLE USER PAGE------------------------------------- */
.single-user-main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  /* position: relative; */
}

.single-user-main .top-part{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.single-user-main .top-part .left-side-cont{
  height: auto;
  flex-wrap: wrap;
  align-items: center;

}

.single-user-main .top-part .left-side-cont .profile-img-div{
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  border-radius:0;
}



.single-user-main .top-part .left-side-cont .profile-info-div{
  width: 100%;
  height: 400px;
  border-radius:0;
}


.single-user-main .mid-part{
    height: 400px;
    border-radius: 0;
    overflow-y: auto;
}



.single-user-main .mid-part .sample-header .sub-header{
  font-size: 0.8rem;
}

.single-user-main .mid-part .sample-header .main-header{
  font-size: 1rem;
}


.single-user-main .mid-part-two{
  height: auto;
  flex-wrap: wrap;

}

.single-user-main .mid-part-two .inner-section{
  width: 100%;
  height: 400px;
  margin-top: 20px;
}


.inner-comp-container .sample-header .sub-header{
  font-size: 0.8rem;
}

.inner-comp-container .sample-header .main-header{
  font-size: 1rem;
}

.single-user-main .bottom-part .admin-btn{
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 0.8rem;
}





/* --------------------------------------------PROFILE PAGE------------------------------------------- */
.profile-main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.profile-main .user-details-container{
  width: 100%;
  height: auto;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}


.profile-main .user-details-container .details-section{
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
  overflow-y: auto;
}



.profile-main .user-details-acc-container{
  width: 98%;
  height: 500px;
}



.profile-main .user-details-acc-container .details-actual .reg-div{
  width: 100%;
}

 

/* ------------------------------- AIRTIME ACCOUNT DETAILS-------------------------  */


.airtime-acc-container .airtime-account{
  width: 90%;
  margin: 10px auto;
}


/* ---------------------COMPANY REG FORM-------------------------------- */

.company-reg-form .input-group{
  width: 100%;
  margin: 5px auto;
 }
 



 /* ------------------------SETTINGS PAGE--------------------------------------- */
 .settings-sections{
  padding: 10px;
}



/* -------------------------CONSTANTS FORM-------------------------- */


.constant-form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
   padding: 5px;
}


.constant-form .input-group{
 width: 100%;
 margin: 5px auto;
}



/* ----------------------------------------MODALS SECTION---------------------------------------- */




.any-modal-main .sale-modal-body{
  width: 90%;
}

.any-modal-main .api-modal-body{
  width: 90%;
}

.inner-modal-main .inner-modal-body{
  width: 90%;
  height: 80%;
}


.inner-modal-main .inner-modal-body.smallc{
  width: 90%;
  height: auto;
}



/* -----------NEW TICKET MODAL---------- */

.new-ticket-modal-container{
  width: 90%;
  height: 70%;
}


.new-ticket-modal-container .new-ticket-form .nt-form-input{
  width: 90%;
}

.new-ticket-modal-container .new-ticket-form .nt-form-area{
  width: 90%;
}


/* --------------------------------SINGLE TICKET PAGE ---------------------------------------------------- */


.single-ticket-page-main .stp-ticket-section{
  width: 100%;
  margin-bottom: 40px;
}


.single-ticket-page-main .stp-comments-section{
  width: 100%;
}

/* ---------------------------------------------------OPERATIONS PAGE----------------------------------------------- */

.operations-main-container .top-part .info-section{
  width: 100%;
}



/* --------------------------------------- SALES PAGE-------------------------------------------------- */


.sales-main-container .sales-container .sales-header .sub-header{
    font-size: 0.8rem;
}

.sales-main-container .sales-container .sales-header .main-header .actual-header{
    font-size: 0.9rem;
    font-weight: 400;
}



/* ----------------------------------CONSTANT CONTAINER------------------------------------ */




.constant-container .kyc-doc-div{
  width: 95%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--succes);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 20px;

}

  /* no data section */

  .no-data-sec{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--box-shadow);
    text-transform: capitalize;
  }



  /*--------------- Sales header custom-------------------------------------------------------- */

.sales-main-container .sales-container .sales-header-cus .left-sect{
display: none;
}
.sales-main-container .sales-container .sales-header-cus .right-sect{
width: 100%;
}




}



@media (max-width: 600px) {

  /* -------------LOG REG SECTIONS---------------- */

  .container .cover{
    display: none;
  }
  .form-content .login-form,
  .form-content .signup-form{
    width: 100%;
  }
  .form-content .signup-form{
    display: none;
  }
  .container #flip:checked ~ .forms .signup-form{
    display: block;
  }
  .container #flip:checked ~ .forms .login-form{
    display: none;
  }


  .container{
    width: 90%;
    border-radius: 10px;
    padding: 20px 10px;
  }
  

  .forms .form-content .title{
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  

  .forms .form-content .input-box{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 15px 0;
    position: relative;
  }

  .form-content .input-box i{
    font-size: 1rem;
  }

 
/* } */



/* -----------------------------MOBILE SIDE NAV ------------------------------------------------------------ */
.mobile-side-navbar-main-container{
  width:70%;
}



  /* --------------------------------------------TOP NAV BAR ----------------------------------------------- */

.top-nav-main .right-section .search-form{
 display: none;
}
/* -----------------------------------------------------MAIN DASHBOARD-------------------------------------------- */
  

.main-dashboard-main .top-card-container{
  justify-content: center;
}


.main-dashboard-main .top-card-container .top-card-div{
  width: 90%;
  margin-bottom: 30px;
}


.main-dashboard-main .small-graghs-container .small-graph{
  width: 100%;
}

.sales-main-container .sales-container .sales-table-container.toosho{
  height: 74%;
}
.sales-main-container .sales-container .sales-header-cus{
  height: 25%;
}


.search-date-form-container .search-date-form{
  flex-direction: column;
  justify-content: space-evenly;
}
.search-date-form-container .search-date-form  .input-group,
.search-date-form-container .search-date-form  .search-date-btn{
  margin: 5px auto;
}

.search-date-form-container .search-date-form-hearder{
 text-align: center;
}


}

